<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Educations</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <template>
        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>

        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#383d3f"
              class="mr-1"
              icon
              @click="uploadFile"
              v-on="on"
            >
              <v-icon>{{ icons.upload }}</v-icon>
            </v-btn>
          </template>
          <span>Import Educations</span>
        </v-tooltip> -->
      </template>
    </v-app-bar>

    <v-progress-linear
      indeterminate
      v-if="educationLoading"
    ></v-progress-linear>

    <div
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="!educationLoading"
      infinite-scroll-distance="10"
    >
      <v-container>
        <v-row align="stretch" v-if="educations.data">
          <v-col
            v-for="education in educations.data"
            :key="education.id"
            cols="12"
            md="4"
          >
            <EducationCard
              :education="education"
              @click="
                $router.push({
                  name: 'education.item',
                  params: { id: education.id },
                })
              "
            />
          </v-col>
          <v-col
            cols="12"
            align="center"
            justify="center"
            v-if="educations.data.length === 0"
          >
            No match found.
          </v-col>
        </v-row>
      </v-container>
    </div>

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
    ></MediaUploader>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import EducationCard from '@/components/education/EducationCard'
import MediaUploader from '@/components/MediaUploader'
import infiniteScroll from 'vue-infinite-scroll'
import { mdiMagnify, mdiFileUpload } from '@mdi/js'
import debounce from 'lodash/debounce'

export default {
  name: 'EducationCategoriesPage',
  mixins: [ControlsMixin],
  directives: {
    infiniteScroll,
  },
  components: {
    AppBarNavIcon,
    EducationCard,
    MediaUploader,
  },
  computed: {
    ...mapState('education', {
      educations: (state) => state.educations,
      educationLoading: (state) => state.educationLoading,
    }),
  },
  data() {
    return {
      showSearch: false,
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        search: mdiMagnify,
        upload: mdiFileUpload,
      },
    }
  },
  mounted() {
    this.getEducations()
  },
  methods: {
    ...mapMutations({
      clearEducations: 'education/clearEducations',
    }),
    ...mapActions({
      getEducations: 'education/getEducations',
      uploadEducations: 'education/uploadEducations',
    }),
    loadMore() {
      if (
        this.educations?.meta?.current_page < this.educations?.meta?.last_page
      ) {
        this.getEducations({
          loadMore: true,
          page: this.educations.meta.current_page + 1,
        })
      }
    },
    search: debounce(function () {
      this.clearEducations()
      this.fetchEducations()
    }, 600),
    async fetchEducations(page = 1) {
      if (this.loading) return
      let params = {
        page,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getEducations(params)
      this.loading = false
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
    uploadFile() {
      this.$refs.triggerUpload.openFile()
    },
    onMediaLoad(data) {
      this.uploadEducations(data.file)
    },
  },
}
</script>
